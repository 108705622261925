import React from "react";
import { ScrollBarSpacer, ScrollWindow } from './Scrollview.styles';
import { ScrollviewProps } from './Scrollview.types';

export const Scrollview: React.FC<ScrollviewProps> = ({ maxSize = 200, children }) => {
  return (
    <ScrollWindow maxSize={maxSize} autoHide={false}>
      <ScrollBarSpacer>
        {children}
      </ScrollBarSpacer>
    </ScrollWindow>
  )
}