import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, LoaderAnimated } from '../../components';
import { useUserStatus } from '../UserStatus';
import { useOpportunities } from './DealReg.hooks';
import { DealSummaryContainer } from './DealSummary.styles';
import { DealSummaryMode, DealSummaryProps } from './DealSummary.types';

export const DealSummary: React.FC<DealSummaryProps> = ({
    title,
    logoUrl,
    oem,
    borderColor,
    playbookUrl,
    mode
}) => {

    const { campaignId } = useParams();
    const { opportunities, isLoading } = useOpportunities(campaignId || null, null);
    const { userStatus, isUserStatusLoading } = useUserStatus(campaignId);

    let stats = {
        openDealCount: 0,
        pipelineValue: 0,
        closedDealCount: 0,
        valueWon: 0
    }

    if (opportunities && !isLoading) {
        stats.openDealCount = opportunities.filter(d => d.oem == oem && d.status == "OPEN").length;
        stats.pipelineValue = opportunities.filter(d => d.oem == oem && d.status == "OPEN").reduce(function (a, b) { return a + b.value; }, 0);
        stats.closedDealCount = opportunities.filter(d => d.oem == oem && d.status == "WON" || d.status == "LOST").length;
        stats.valueWon = opportunities.filter(d => d.oem == oem && d.status == "WON").reduce(function (a, b) { return a + b.value; }, 0);

        console.log(opportunities);
    }

    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });


    return (
        <DealSummaryContainer style={{ borderColor: borderColor }} className={`mode-${DealSummaryMode[mode].toLowerCase()}`}>
            {((mode == DealSummaryMode.MyDeals && isLoading) || (mode == DealSummaryMode.MyPoints && isUserStatusLoading)) ? <LoaderAnimated /> : <>
                {(title) ?
                    <div className='dealSummaryTitle'>{title}</div>
                    : <></>}
                {(logoUrl) ?
                    <div>
                        <img src={logoUrl} />
                    </div> : <></>}
                {(mode == DealSummaryMode.MyDeals) ? <>
                    <div className='download-playbook-link'>
                        <Button onClick={() => window.open(playbookUrl, '_blank')} primary btn-primary>DOWNLOAD PLAYBOOK</Button>
                    </div>


                    <div className='stat-list-container'>
                        <div className='stat-list'>
                            <div className='stat-row'>
                                <div className='stat-value'>{stats.openDealCount}</div>
                            </div>
                            <div className='stat-row'>
                                <div className='stat-value'>{formatter.format(stats.pipelineValue)}</div>
                            </div>
                            <div className='stat-row'>
                                <div className='stat-value'>{stats.closedDealCount}</div>
                            </div>
                            <div className='stat-row'>
                                <div className='stat-value'>{formatter.format(stats.valueWon)}</div>
                            </div>
                        </div>
                        <div className='stat-list'>
                            <div className='stat-row'>
                                <div className='stat-label'>Number of open deals</div>
                            </div>
                            <div className='stat-row'>
                                <div className='stat-label'>Value of pipeline</div>
                            </div>
                            <div className='stat-row'>
                                <div className='stat-label'>Number of deals closed</div>
                            </div>
                            <div className='stat-row'>
                                <div className='stat-label'>Value of deals won</div>
                            </div>
                        </div>
                    </div></> : <></>}

                {(mode == DealSummaryMode.MyPoints) ?
                    <>
                    <h3>{userStatus?.totalPoints} - My points</h3><div className='download-playbook-link'>
                        <Button onClick={() => window.open(playbookUrl, '_blank')} primary btn-primary>DOWNLOAD PLAYBOOK</Button>
                    </div>
                    </> : <></>}
            </>}
        </DealSummaryContainer>
    );
}