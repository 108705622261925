import { DefaultTheme } from 'styled-components';
import { BaseOptions } from '../declarations/styled';
import { Colours } from './colours';

const ThemeColours = {
  PrimaryColour: Colours.PRIMARY,
  SecondaryColour: Colours.SECONDARY,
  TertiaryColour: Colours.TERTIARY,
  TextColour: Colours.BLACK,
  HighlightColour: Colours.PRIMARY,
  HighlightTextColour: Colours.WHITE,
  ButtonBackgroundColour: Colours.SECONDARY,
  ButtonTextColour: Colours.WHITE,
  ButtonRadius: "1000px",
};

const AnimationSettings = {
  AnimationTimingFast: '0.3s',
  AnimationTimingMedium: '0.5s',
  AnimationTimingSlow: '1s',
}

const BaseSettings: BaseOptions = {
  BorderRadius: '0.625em',
  DropShadowColor: Colours.BLACK,
  DropShadowAlpha: 0.2,
  DropShadowPlacement: '0px 3px 6px',
}

export const Theme: DefaultTheme = {
  Id: "canonfocuspep",
  ...ThemeColours,
  ...AnimationSettings,
  Base: BaseSettings,
  Grid: {
    Columns: 12,
    GutterSize: '1em',
    Breakpoints: {
      SM: 600,
      MD: 760,
      LG: 900,
      XL: 1200,
      MAX: 1500,
    },
  },
  Splash: {
    BackgroundImageUrl: "",
    LogoImageUrl: "",
    VideoUrl: ""
  },
  Home: {
    LowerBackgroundImageUrl: "https://canonfocuspep.blob.core.windows.net/thumbnails/flex-incentive/home-lower-bg.png",
    PrizeSummaryBackgroundImageUrl: "",
    ActionBarBackgroundColor: "#cc0000",
    ActionBarTextColor: Colours.BLACK
  },
  Header: {
    BackgroundColour: Colours.PRIMARY,
    TextColour: Colours.WHITE,
    LockupImageUrl: ""
  },
  Nav: {
    TextColour: Colours.WHITE,
    HoverColour: Colours.WHITE,
    TextDecoration: 'none',
    TextDecorationOnHover: 'none',
  },
  Card: {
    BorderRadius: BaseSettings.BorderRadius,
    ContentMargin: '1em',
    ContentPadding: '1em',
    BackgroundColor: Colours.WHITE,
    HasDropShadow: true,
    DropShadowColor: BaseSettings.DropShadowColor,
    DropShadowAlpha: BaseSettings.DropShadowAlpha,
    DropShadowPlacement: BaseSettings.DropShadowPlacement,
  },
  ListItem: {
    BorderWidth: "0px",
    BorderColor: "",
    BorderStyle: "",
    BorderRadius: ""
  },
  DividerImageUrl: "",
  DealSummary: {
    BackgroundColor: "transparent",
    BorderWidth: "0px",
    BorderRadius: "0px",
    Opacity: "100%",
    BorderColor: "#cc0000",
    BorderStyle: "solid"
  },
  Leaderboard: {
    RowBackgroundColour: ""
  }
};
