import styled from "styled-components";
import { splitValueFromUnit } from "../../style/helpers";
import { RowProps } from "./Row.types";

export const Row = styled.div<RowProps>`
    display: flex;
    flex-direction: ${props => props.direction || 'row'};
    justify-content: ${props => props.mainAlignment || 'flex-start'};
    align-items: ${props => props.crossAlignment || 'stretch'};
    flex-wrap: ${props => typeof props.wrap === 'undefined' || props.wrap ? 'wrap' : 'nowrap'};
    margin-left: ${props => {
      const {value, unit} = splitValueFromUnit(props.theme.Grid.GutterSize);

      return `${0 - value/2}${unit}`;
    }};
    margin-right: ${props => {
      const {value, unit} = splitValueFromUnit(props.theme.Grid.GutterSize);

      return `${0 - value/2}${unit}`;
    }};
`;
