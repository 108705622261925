import { ContentUriModel } from "outbound-api-client/lib/models";
import { DefaultTheme } from "styled-components";
import { DealSummaryMode } from "../modules/OpportunityManagement/DealSummary.types";

export enum LayoutType {
  Canon,
  Legacy,
  Emailer
}

export interface ConfigProps {
  appUri: string;
  apiUri: string;
  clientId: string;
  clientSecret: string;
  campaignIdentifier: string;
  layoutType: LayoutType;
  theme: DefaultTheme;
  enableProps: ConfigEnableProps | null;
  learnProps: ConfigLearnProps;
  applicationTitle: string;
  colours: any;
  footerProps: ConfigFooterProps;
  campaignProps: CampaignProps;
  opportunityManagementProps: OpportunityManagementProps;
  navigationProps: NavigationProps;
}

interface NavigationProps {
  showSalesTools: boolean;
  showQuiz: boolean;
  showLeaderboard: boolean;
}

interface PrizeSectionProps {
  show: boolean;
  title: string | null;
  intro: string | null;
  dividerImageUrl: string | null;
}

interface OpportunityManagementVendorProps {
  value: string;
  imageUrl: string;
}

interface OpportunityManagementProps {
  vendors: Array<OpportunityManagementVendorProps>;
  dealRegForm?: DealRegFormProps;
}

interface DealRegFormProps {
  dealRegFormSchema: any;
  dealRegForm: Array<DealRegFormFieldProps>;
  dealRegFormInitialValues: any;
  dealsEditable: boolean;
}

interface DealRegFormFieldProps {
  name: string;
  label: string;
  dataType: DealRegFormFieldDataType;
  values: Array<any>
}

export enum DealRegFormFieldDataType
{
  Text = 0,
  Number = 1,
  Date = 2,
  Choice = 3,
  ImageChoice = 4
}

interface CampaignProps {
  termsButtonText: string;
  termsButtonSubmittingText: string;
  prizeSection: PrizeSectionProps;
  dealSummarySections: DealSummaryProps[];
}

interface DealSummaryProps {
  logoUrl: string | null;
  oem: string | null,
  playbookUrl: string;
  borderColour: string;
  title: string | null;
  mode: DealSummaryMode;
}

interface ConfigEnableProps {
  groupByCategory: boolean;
  searchEnabled: boolean;
  downloadIcons: boolean;
  tiles: Array<ConfigEnableTileProps> | null;
}

interface ConfigFooterProps {
  smallPrint: string;
  helpUrl: string;
  helpText: string;
  privacyUrl: string;
  privacyText: string;
  termsUrl: string | null;
  termsText: string | null;
  cookiePolicyUrl: string | null;
  cookiePolicyText: string | null;
}

export interface ConfigEnableTileProps {
  title: string;
  summary: string;
  url: string;
  target: string;
  thumnbails: ContentUriModel[];
}

export interface ConfigLearnProps {
  groupCategories: boolean;
}