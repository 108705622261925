import { useSearchParams } from 'react-router-dom';

export const useReferrer = () => {
  const [searchParams] = useSearchParams();
  
  const getReferrer = async () => {
        
    let referrerCode = "";

    if (searchParams.get("r") !== null && searchParams.get("r") !== "")
    {
      referrerCode = searchParams.get("r") as string;
    }

    if (referrerCode !== "") {
      localStorage.setItem("referrerCode", referrerCode);
      window.location.href = '/';
    }
  };

  return {
    getReferrer
  }
}