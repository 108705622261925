import { useTheme } from 'styled-components';
import { Helmet } from 'react-helmet';
import { SplashContainer } from './Splash.styles';
import { doLogin } from '../../hooks';
import { apiService, configService } from '../../services';
import { useCampaignsDispatch } from '../../modules';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { useRef, useState } from 'react';

export const Splash: React.FC = () => {

  const [ videoPlaying, setVideoPlaying ] = useState(false);
  const theme = useTheme();
  const isLoggedIn = apiService.instance.isLoggedIn();
  const dispatch = useAppDispatch();
  const { campaigns, hasContent } = useCampaignsDispatch(dispatch);
  const defaultCampaign = hasContent && campaigns.length == 1 ? campaigns[0] : null;
  const videoEl: any = useRef(null);
  const videoBgEl: any = useRef(null);

  function playVideo() {
    if (videoEl.current && videoBgEl.current && !videoPlaying)
    {
      videoBgEl.current.style.display = "none";
      videoEl.current.style.display = "block";
      videoEl.current.setAttribute( 'controls', '' );
      videoEl.current.play();
      setVideoPlaying(true);
    }
  }

  return (
    (isLoggedIn && hasContent && defaultCampaign != null) ? <Navigate to={`/incentive/${defaultCampaign.identifier}`} replace={true} /> :
      <SplashContainer>
        <Helmet>
          <title>{configService.current().applicationTitle}</title>
        </Helmet>
        <div className="ident-container">
          <img src={theme.Splash.LogoImageUrl} />
        </div>

        {(theme.Splash.VideoUrl) ? 
          <div className="video" onClick={playVideo}>
            <img src="/images/video-bg.png" ref={videoBgEl} />
            <video id="videoPlayer" style={{display: "none"}} ref={videoEl}>
              <source src={`${theme.Splash.VideoUrl}`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
          </div> : <></>}

        <div className="login-container">
          <button className='btn btn-primary m-x-05 text-decoration-none' onClick={() => doLogin()}>LOGIN/REGISTER</button>
        </div>

      </SplashContainer>
  )
}