import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useFeedItems, useHeroItems, useUserStatusRefresh } from '../modules';
import { CardLoader, Cell, Row } from '../components';
import { useAppDispatch, useAppSelector } from '../store';
import { selectUserStatus } from '../store/slices/userStatus.slice';
import { configService } from '../services';

export const Home: React.FC = () => {
  const [isPageLoading, setPageLoading] = useState(true);
  
  const dispatch = useAppDispatch();
  const userStatus = useAppSelector(selectUserStatus);
  const {
    FeedContent,
    hasContent,
    isLoading
  } = useFeedItems();

  const {
    HeroFeed
  } = useHeroItems();
  
  useUserStatusRefresh(dispatch);

  useEffect(() => {
    if (!isLoading && hasContent && isPageLoading && userStatus.isLoaded) {
      setPageLoading(false);
    }
  }, [isLoading, hasContent, isPageLoading, userStatus.isLoaded]);

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Home</title>
      </Helmet>
      <div className='hero-container'>
        <HeroFeed />
      </div>
      <h2 className="text-center font-title  m-bottom-0">Partner Feed</h2>
      <p className='text-center'>Find out what's going on in Canon.</p>
      <div className="w-100">
        {isPageLoading ? (
          <Row wrap={false} className='overflow-hidden'>
            <Cell baseSize={6} SMSize={4} LGSize={3}>
              <CardLoader />
            </Cell>
            <Cell baseSize={6} SMSize={4} LGSize={3}>
              <CardLoader />
            </Cell>
            <Cell baseSize={6} SMSize={4} LGSize={3}>
              <CardLoader />
            </Cell>
            <Cell baseSize={6} SMSize={4} LGSize={3}>
              <CardLoader />
            </Cell>
          </Row>
        ) : (
          <FeedContent />
        )}
      </div>
    </div>
  )
}