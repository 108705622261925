import styled from "styled-components";

export const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding-left: ${props => props.theme.Grid.GutterSize};
  padding-right: ${props => props.theme.Grid.GutterSize};
  width: 100%;
  min-height: 100vh;

  & >div {
    /*width: 100%; scc-gt23 specific */
  }

  @media (min-width: ${props => props.theme.Grid.Breakpoints.XL}px) {
    max-width: ${props => props.theme.Grid.Breakpoints.MAX}px;
  }
`;

export const GridContainerNoFlex = styled.div`
  justify-content: center;
  flex-wrap: nowrap;
  padding-left: ${props => props.theme.Grid.GutterSize};
  padding-right: ${props => props.theme.Grid.GutterSize};
  width: 100%;
  min-height: 100vh;

  & >div {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.Grid.Breakpoints.XL}px) {
    max-width: ${props => props.theme.Grid.Breakpoints.MAX}px;
  }
`;

export const ProfileZone = styled.div`
  display: none;
  flex-shrink: 0;

  @media (min-width: ${props => props.theme.Grid.Breakpoints.MD}px) {
    display: block;
    width: 250px;
    margin-left: 30px;
  }

  @media (min-width: ${props => props.theme.Grid.Breakpoints.LG}px) {
    width: 270px;
  }

  @media (min-width: ${props => props.theme.Grid.Breakpoints.XL}px) {
    width: 300px;
  }
`;

export const MainZone = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 49px;

  @media (min-width: ${props => props.theme.Grid.Breakpoints.SM}px) {
    padding-top: 0;
  }
`;
