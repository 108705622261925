import { ContentTemplateModel, InteractionEventType } from "outbound-api-client/lib/models";
import { useEffect, useState } from "react";
import { apiService } from "../../services";
import { ContentTemplate } from "./components/ContentTemplate.component";
import { getContentTemplate } from "./ContentTemplate.services";

export const useContentTemplate = (identifier: string) => {
  const [contentTemplate, setContentTemplate] = useState<ContentTemplateModel>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getContentTemplate(identifier)
      .then((contentTemplate: any) => {
        setContentTemplate(contentTemplate);
      })
      .catch((err: any) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!isLoading && contentTemplate && contentTemplate.template) {
      apiService.instance.createInteractionSimple(InteractionEventType.AppArticleView, contentTemplate.identifier);
    }
  }, [isLoading, contentTemplate]);

  const ContentTemplateContent = () => {
    return (
      <>
      {(contentTemplate !== undefined) ? <ContentTemplate {...contentTemplate} /> : <></>}
      </>
    )
  }

  return {
    isLoading,
    hasError,
    hasContent: !!contentTemplate,
    ContentTemplateContent
  }
}