import React, { useState } from 'react';
import { FormikHelpers } from 'formik';
import { Form, Field, TextInput, DateInput, ImageRadioInput, LoaderAnimated, SelectInput, Portal } from '../../components';
import { DealRegFormSchema, DealRegFormValues } from './DealRegForm.schema';
import { toast } from 'react-toastify';
//import { apiService } from '../../services';
import { SalesIncentiveOpportunityModel, OpportunityProductModel, OpportunityType, OpportunityStatus, InteractionEventType } from 'outbound-api-client/lib/models';
import { useNavigate, useParams } from 'react-router-dom';
import { useOpportunities } from './DealReg.hooks';
import { apiService, configService } from '../../services';
import { Button, Modal } from 'react-bootstrap';
import { DealRegFormFieldDataType } from '../../services/config.service.types';

export const DealRegForm: React.FC = () => {

  const { campaignId } = useParams();
  const { opportunityId } = useParams();
  const needsToLoad = typeof opportunityId !== "undefined";
  const { opportunities, isLoading } = useOpportunities(null, needsToLoad ? opportunityId : null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState<SalesIncentiveOpportunityModel | null>(null);
  const handleClose = () => setShow(false);
  
  let initialValues = configService.current().opportunityManagementProps.dealRegForm?.dealRegFormInitialValues;

  if (needsToLoad && !isLoading && opportunities.length == 1) {
    initialValues = {
      status: opportunities[0].status,
      manufacturer: opportunities[0].oem,
      customerName: opportunities[0].customerName,
      productType: opportunities[0].products[0].description,
      opportunityDetails: opportunities[0].details,
      estimatedSalesValue: opportunities[0].value,
      estimatedCloseDate: new Date(opportunities[0].opportunityDate)
    };
  
  }


  const confirmCloseAndSubmit = async () => {
    if (formData != null)
    {
      await doSubmit(formData);
      navigate(`../${campaignId}/mydeals`, { replace: true });
    }
  }

  const onSubmit = async (formValues: DealRegFormValues, formikBag?: FormikHelpers<DealRegFormValues>) => {

    const data = mapFormValues(formValues, formikBag);

    if (data == null)
    {
      toast("We can't submit right now. Try again later.", { type: toast.TYPE.ERROR });
    }
    else
    {
      switch (data.status) {
        case "WON":
        case "LOST":
          setShow(true);
          setFormData(data);
          break;
          default:
            await doSubmit(data);
            navigate(`../${campaignId}/mydeals`, { replace: true });
            if (formikBag) formikBag.resetForm();
          break;
      }
    }
  }

  const doSubmit = async (data: SalesIncentiveOpportunityModel): Promise<boolean> => {

      toast('Success! You have registered your deal.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });

      await apiService.instance.createUpdateSalesIncentiveOpportunity(data);

      let score: number = data.value;

      if (needsToLoad)
      {
        if (data.status != "LOST")
          score = score - opportunities[0].value;
        else
          score = 0 - opportunities[0].value;
      }
      else
      {
        if (data.status == "LOST")
          score = 0;
      }

      await apiService.postInteraction(InteractionEventType.DealRegistration, "DealRegistration", null, campaignId, score);

      return true;
  }

  const mapFormValues = (formValues: DealRegFormValues, formikBag?: FormikHelpers<DealRegFormValues>) : SalesIncentiveOpportunityModel | null => {
    try {

      if (!campaignId)
      {
        return null;
      }

      const formData = {
        ...formValues
      };

      let salesIncentiveOpportunityModel = new SalesIncentiveOpportunityModel();

      let statusValue: OpportunityStatus = OpportunityStatus.Open;

      switch (formData.status) {
        case "WON":
          statusValue = OpportunityStatus.Won;
          break;

        case "LOST":
          statusValue = OpportunityStatus.Lost;
          break;
      }

      if (opportunityId)
      {
        salesIncentiveOpportunityModel.opportunityId = opportunityId;
      }

      salesIncentiveOpportunityModel.status = statusValue;
      salesIncentiveOpportunityModel.oem = formData.manufacturer ? formData.manufacturer : '';
      salesIncentiveOpportunityModel.campaignIdentifier = campaignId;
      salesIncentiveOpportunityModel.customerName = formData.customerName;
      salesIncentiveOpportunityModel.opportunityDate = formData.estimatedCloseDate;
      salesIncentiveOpportunityModel.details = formData.opportunityDetails;
      salesIncentiveOpportunityModel.type = OpportunityType.Opportunity;
      salesIncentiveOpportunityModel.status = statusValue;
      salesIncentiveOpportunityModel.value = formData.estimatedSalesValue;
      salesIncentiveOpportunityModel.products = new Array<OpportunityProductModel>();
      salesIncentiveOpportunityModel.products.push({ description: formData.productType, productSku: "", quantity: 1 });

      return salesIncentiveOpportunityModel;
    }
    catch {
      return null;
    }
  }
 
  return (
    (needsToLoad && isLoading) ? <LoaderAnimated /> :
      <>
        <Form
          initialValues={initialValues}
          schema={DealRegFormSchema}
          onSubmit={onSubmit}
          submitButtonText={(!needsToLoad) ? "SUBMIT" : "UPDATE"}
          submittingButtonText={(!needsToLoad) ? "SUBMITTING" : "UPDATING"}
          showCancel={true}
        >
          <fieldset>
            {(configService.current().opportunityManagementProps.dealRegForm?.dealRegForm?.map(field => {

              switch (field.dataType)
              {
                case DealRegFormFieldDataType.Date:
                  return (<Field
                    name={field.name}
                    label={field.label}
                    component={DateInput} />)
                break;
                case DealRegFormFieldDataType.ImageChoice:
                  return (<Field
                    name={field.name}
                    label={field.label}
                    component={ImageRadioInput}
                    valuesImage={field.values} />)
                break;
                case DealRegFormFieldDataType.Choice:
                  return (<Field
                    name={field.name}
                    label={field.label}
                    component={SelectInput}
                    values={field.values}
                  />)
                break;
                default:
                case DealRegFormFieldDataType.Text:
                  return (<Field
                    name={field.name}
                    label={field.label}
                    component={TextInput}
                  />)
                break;
              }
            }))}
          </fieldset>
        </Form>
 <Portal>     
  
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title><div className={configService.current().clientId}><p><b>Close deal</b></p></div></Modal.Title>
          </Modal.Header>
          <Modal.Body><div className={configService.current().clientId}><p>You are about to submit a closed deal which cannot subsequently be edited. Are you sure you want to continue?</p></div></Modal.Body>
          <Modal.Footer>
            <div className={configService.current().clientId}>
            
            <Button variant="primary" onClick={confirmCloseAndSubmit}>
              Yes
            </Button>

            <Button variant="secondary" onClick={handleClose} className="clear-button">
              No
            </Button>
            </div>
          </Modal.Footer>
        </Modal>
 
        </Portal>
      </>
  )
}