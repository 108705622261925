import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Carousel, Cell, Row } from '../../../components';
import { CarouselSettingProps } from '../../../components/Carousel/Carousel.types';
import { useWindow } from '../../../hooks';
import { TrainingCollectionProps } from '../Training.types';
import { Training } from './Training.component';
import { QuizTileWrapper } from './Training.styles';

export const TrainingCollection: React.FC<TrainingCollectionProps> = ({
  category,
  collection,
  showTitle
}) => {
  const { Grid } = useContext(ThemeContext);
  const { width } = useWindow();

  const settingsLG: CarouselSettingProps = {
    slidesToShow: 3,
    slidesToScroll: 3,
  }

  const settingsSM: CarouselSettingProps = {
    slidesToShow: 2,
    slidesToScroll: 2,
  }


  return (
    <section>
      {showTitle && (<h2 className="font-title text-center">{category}</h2>)}
      <div className="w-100">
        {collection.map((season, inx) => {
          if (width > Grid.Breakpoints.LG) {
            return (
              <Row>
                {season.episodes.map((e, inx) => (
                  <Cell baseSize={3} key={inx}>
                    <QuizTileWrapper>
                      <Training {...e} />
                    </QuizTileWrapper>
                  </Cell>
                ))}
              </Row>
            )
          } else {
            return (
              <Carousel
                key={inx}
                RenderComponent={Training}
                CarouselItems={season.episodes}
                slidesToShow={4}
                slidesToScroll={4}
                settingsLG={settingsLG}
                settingsSM={settingsSM}
                showArrows
              />
            )
          }
        })}
      </div>
    </section>
  )
}