import { ColoursGT23 } from "../style/colours.gt23";
import { GT23Theme } from "../style/theme.gt23";
import { string, number, date } from 'yup';
import { ConfigProps, DealRegFormFieldDataType, LayoutType } from "./config.service.types";
import { DealSummaryMode } from "../modules/OpportunityManagement/DealSummary.types";

export const SccGT23Config: ConfigProps = {
  appUri: `${process.env.REACT_APP_URL}`,
  apiUri: `${process.env.REACT_APP_API_URL}`,
  clientId: "scc-gt23",
  clientSecret: "GI4X9391eIcMLAozSUxtPSMR6x5Qhz2cyJH15BLBqRz+NdH+CQWVZACUfn901/6/QMpVsxQakhBDAQrpLRoGQw==",
  applicationTitle: "SCC GT23",
  campaignIdentifier: "",
  layoutType: LayoutType.Legacy,
  theme: GT23Theme,
  colours: ColoursGT23,
  learnProps: {
    groupCategories: false
  },
  enableProps: {
    groupByCategory: true,
    searchEnabled: false,
    downloadIcons: true,
    tiles: null
  },
  footerProps: {
    smallPrint: "&copy; Outbound Field Marketing Services",
    helpUrl: "mailto:campaignsupport@out-bound.co.uk?subject=GT23 Contact",
    helpText: "Help",
    privacyUrl: "https://www.scc.com/privacy-notice-employees/",
    privacyText: "Privacy Information",
    termsUrl: "https://sccgt23.blob.core.windows.net/legal/SCC%20GT23%20Incentive%20Terms%20and%20Conditions.pdf",
    termsText: "Terms & Conditions",
    cookiePolicyUrl: null,
    cookiePolicyText: null
  },
  campaignProps: {
    termsButtonText: "SUBMIT",
    termsButtonSubmittingText: "SUBMITTING",
    prizeSection: {
      show: true,
      title: "THE PRIZES",
      intro: "Learn more about the amazing prizes we have up for grabs!",
      dividerImageUrl: "https://sccgt23.blob.core.windows.net/images/orange-divider.png"
    },
    dealSummarySections: [
      {
        title: null,
        logoUrl: "https://sccgt23.blob.core.windows.net/images/logo-hp.png",
        oem: "HP",
        playbookUrl:"https://sccgt23.blob.core.windows.net/playbook/15915_GT23%20Playbook%20-%20HP.pdf",
        borderColour: "#40DEFA",
        mode: DealSummaryMode.MyDeals
      },
      {
        title: null,
        logoUrl: "https://sccgt23.blob.core.windows.net/images/logo-hpe.png",
        oem: "HPE",
        playbookUrl:"https://sccgt23.blob.core.windows.net/playbook/15915_GT23%20Playbook%20-%20HPE.pdf",
        borderColour: "#01A982",
        mode: DealSummaryMode.MyDeals
      }
    ]
  },
  opportunityManagementProps: {
    vendors: [
      { value: "HP", imageUrl: "https://sccgt23.blob.core.windows.net/images/logo-hp.png" },
      { value: "HPE", imageUrl: "https://sccgt23.blob.core.windows.net/images/logo-hpe.png" }
    ],
    dealRegForm: {
      dealRegFormSchema: {
        manufacturer: string().required('You must select a vendor'),
        status: string().required('You must select a status'),
        customerName: string().required('You must enter a customer name'),
        productType: string().required('You must enter a product type'),
        opportunityDetails: string().required('You must enter the opportunity details'),
        estimatedSalesValue: number().positive("Amount must be greater than zero").required('You must enter a value').typeError('Amount must be a number only'),
        estimatedCloseDate: date().required('You must enter an estimated close date').nullable(true),
      },
      dealRegForm: [
        { name: "manufacturer", label: "Choose your vendor", dataType: DealRegFormFieldDataType.Text, values: [
          { value: "HP", imageUrl: "https://sccgt23.blob.core.windows.net/images/logo-hp.png" },
          { value: "HPE", imageUrl: "https://sccgt23.blob.core.windows.net/images/logo-hpe.png" }
        ] },
        { name: "status", label: "Status", dataType: DealRegFormFieldDataType.Text, values: [{ identifier: "OPEN", label: "Open", value: "OPEN" }, { identifier: "WON", label: "Won", value: "WON" }, { identifier: "LOST", label: "Lost", value: "LOST" }] },
        { name: "customerName", label: "Customer Name", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "productType", label: "Product Type", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "opportunityDetails", label: "Opportunity Details", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "estimatedSalesValue", label: "Estimated Sales Value (£)", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "estimatedCloseDate", label: "Estimated Close Date", dataType: DealRegFormFieldDataType.Date, values: [] }
      ],
      dealRegFormInitialValues: {
        status: 'OPEN',
        manufacturer: '',
        customerName: '',
        productType: '',
        opportunityDetails: '',
        estimatedSalesValue: 0,
        estimatedCloseDate: new Date()
      },
      dealsEditable: true
    }
  },
  navigationProps: {
    showSalesTools: true,
    showQuiz: true,
    showLeaderboard: false
  }
}