import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { TypedSchema } from 'yup/lib/util/types';
import { Button } from '..';
import { FormProps } from './Form.types';

export function Form<T extends TypedSchema>({
  initialValues,
  schema,
  onSubmit,
  children,
  submitButtonText = 'SUBMIT',
  submittingButtonText = 'SUBMITTING',
  showCancel
}: FormProps<T>) {

  const navigate = useNavigate();

  const doCancel = () => {
    navigate(-1);
}

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      {props => (
        <form onSubmit={props.handleSubmit}>
          <div>
            <div className='form-fields'>
              {children}
            </div>
            <div>
              <Button primary type='submit' isLoading={props.isSubmitting}>{props.isSubmitting ? submittingButtonText : submitButtonText }</Button>
              {(showCancel) ? <Button type='button' cssClass='btn-secondary clear-button' onClick={doCancel}>CANCEL</Button> : <></> }
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}