import styled from "styled-components";

export const SplashContainer = styled.div`
min-height: 100vh;
display: flex;
flex: 1;
flex-direction: column;
height: 100vh;
background: url(${props => props.theme.Splash.BackgroundImageUrl}) no-repeat center center fixed;
background-size: cover; 
justify-content: center;
align-items: center;
padding: 75px;

& .ident-container {
    margin-bottom: 75px;
    max-width: 80%;

    & img {
        max-width: 100%;
    }
}

& .video {
    max-width: 533px;
    max-height: 298px;
    background: url('images/video-bg.png') center center;
    background-size: cover;
    border: 1px solid white;
    display: flex;
    cursor: pointer;
    padding: 1px !important;
    margin-bottom: 75px;
}

@media only screen and (max-device-width: 900px) { 
    & .video {
        max-width: 100%;
    }
  }

& .login-container>.btn-primary {
        background-color: ${props => props.theme.ButtonBackgroundColour};
        color: ${props => props.theme.ButtonTextColour};
        border-radius: ${props => props.theme.ButtonRadius};
        border: none;
    }

`