import React from 'react';
import { SecondaryWrapper, SummaryContents, SummaryWrapper } from './ProfileSummary.styles';
import { Progress, StickyWrapper, UserCard, UserPicture } from '..'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { useLeaderboard } from '../../modules';
import { useAppSelector } from '../../store';
import { selectUserStatus } from '../../store/slices/userStatus.slice';

export const ProfileSummary: React.FC = () => {

  const { podium, scores, isLoading  } = useLeaderboard();
  const userStatus = useAppSelector(selectUserStatus);

  const fullName = userStatus?.displayNameSafe ? userStatus.displayNameSafe : "Loading ...";

  return (
    <StickyWrapper>
        <SummaryWrapper>
          <SummaryContents className="text-center">
            <div>
              <UserPicture name={fullName} size={4} useLargerFont cssClass='m-x-auto m-y-05' />
              <div>
                <Link className='text-decoration-none' to="/profile">
                  <FontAwesomeIcon icon={faCog} style={{width: '1.2em', height: 'auto', color: '#000'}} />
                  <p className='font-subheading color-text'>Edit</p>
                </Link>
              </div>
            </div>
            <div className='p-y-05'>
              <p className='font-title font-style-regular m-bottom-0'>{fullName}</p>
              <p className='font-subtitle font-style-regular'>{userStatus?.teamName}</p>
            </div>
            <div>
              <p className='font-heading font-style-regular m-bottom-0'>{userStatus?.currentLevel}</p>
              <div className='p-x-1'>
                <Progress max={userStatus?.nextLevelIn} value={userStatus ? userStatus?.totalPoints : 0} />
              </div>
              <p className='font-smallprint'>Progress: {userStatus?.totalPoints}/{userStatus?.nextLevelIn}</p>
            </div>
          </SummaryContents>
        </SummaryWrapper>
       {!!scores.length && !isLoading && (<SecondaryWrapper className='dropIn'>
          <SummaryContents>
            <h2 className='font-subheading m-top-0'>Top Performers</h2>
            <div>
              {podium.map((person, inx) => (
                <UserCard Name={person.Name} CurrentLevel={person.CurrentLevel} key={inx} />
              ))}
            </div>
          </SummaryContents>
        </SecondaryWrapper>)}
    </StickyWrapper>
  );
}