import { UserStatusModel } from "outbound-api-client/lib/models";
import { useEffect, useState } from "react";
import { apiService } from "../../services";
import { AppDispatch } from "../../store";
import { getUserStatus } from "./UserStatus.services";

export const useUserStatusRefresh = (dispatch: AppDispatch) => {
  useEffect(() => {
    getUserStatus(dispatch);
  }, [dispatch]);
}

export const useUserStatus = (campaignIdentifier = '') => {
  const [userStatus, setUserStatus] = useState<UserStatusModel>();
  const [isUserStatusLoading, setIsUserStatusLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsUserStatusLoading(true);
    apiService.instance.getUserStatus(campaignIdentifier)
    .then((data) => {
      setUserStatus(data);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(()=> {
        setIsUserStatusLoading(false);
      });
  }, []);

  return {
    userStatus,
    isUserStatusLoading,
    hasError,
  }
}
