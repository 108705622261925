import React from 'react';
import { Helmet } from 'react-helmet';
import { ProfileForm } from '../components';
import { configService } from '../services';

export const Profile: React.FC = () => {
  
  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Profile</title>
      </Helmet>
      <ProfileForm />
    </div>
  )
}