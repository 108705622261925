import { DefaultTheme } from 'styled-components';
import { BaseOptions } from '../declarations/styled';
import { Colours } from './colours';

const ThemeColours = {
  PrimaryColour: Colours.PRIMARY,
  SecondaryColour: Colours.SECONDARY,
  TertiaryColour: Colours.TERTIARY,
  TextColour: Colours.BLACK,
  HighlightColour: Colours.PRIMARY,
  HighlightTextColour: Colours.BLACK,
  ButtonBackgroundColour: "#F18923",
  ButtonTextColour: Colours.WHITE,
  ButtonRadius: "5px",
};

const AnimationSettings = {
  AnimationTimingFast: '0.3s',
  AnimationTimingMedium: '0.5s',
  AnimationTimingSlow: '1s',
}

const BaseSettings: BaseOptions = {
  BorderRadius: '0.625em',
  DropShadowColor: Colours.BLACK,
  DropShadowAlpha: 0.2,
  DropShadowPlacement: '0px 3px 6px',
}

export const GT23Theme: DefaultTheme = {
  Id: "scc-gt23",
  ...ThemeColours,
  ...AnimationSettings,
  Base: BaseSettings,
  Grid: {
    Columns: 12,
    GutterSize: '1em',
    Breakpoints: {
      SM: 600,
      MD: 760,
      LG: 900,
      XL: 1200,
      MAX: 1500,
    },
  },
  Splash: {
    BackgroundImageUrl: "https://sccgt23.blob.core.windows.net/images/splash-background.jpg",
    LogoImageUrl: "/images/GT23/logo.png",
    VideoUrl: "https://sccgt23.blob.core.windows.net/video/Sales%20Incentive%20Lake%20Como.mp4"
  },
  Header: {
    BackgroundColour: Colours.PRIMARY,
    TextColour: Colours.WHITE,
    LockupImageUrl: "https://sccgt23.blob.core.windows.net/images/scc-logos.png"
  },
  Home: {
    LowerBackgroundImageUrl: "https://sccgt23.blob.core.windows.net/images/home-lower-bg.jpg",
    PrizeSummaryBackgroundImageUrl: "https://sccgt23.blob.core.windows.net/images/home-prizes-bg.jpg",
    ActionBarBackgroundColor: "#F18923",
    ActionBarTextColor: Colours.WHITE
  },
  Nav: {
    TextColour: Colours.WHITE,
    HoverColour: Colours.WHITE,
    TextDecoration: 'none',
    TextDecorationOnHover: 'none',
  },
  Card: {
    BorderRadius: BaseSettings.BorderRadius,
    ContentMargin: '1em',
    ContentPadding: '1em',
    BackgroundColor: Colours.WHITE,
    HasDropShadow: false,
    DropShadowColor: BaseSettings.DropShadowColor,
    DropShadowAlpha: BaseSettings.DropShadowAlpha,
    DropShadowPlacement: BaseSettings.DropShadowPlacement,
  },
  ListItem: {
    BorderWidth: "1px",
    BorderColor: "#f9cea4",
    BorderStyle: "solid",
    BorderRadius: "5px"
  },
  DividerImageUrl: "https://sccgt23.blob.core.windows.net/images/orange-divider.png",
  DealSummary: {
    BackgroundColor: "#fff",
    BorderWidth: "7px",
    BorderRadius: "0px",
    Opacity: "100%",
    BorderColor: "",
    BorderStyle: ""
  },
  Leaderboard: {
    RowBackgroundColour: ""
  }
};
