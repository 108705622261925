import { useCallback, useEffect, useState } from "react";
import { getTraining } from "./Training.services";
import { TrainingCollection } from "./components/TrainingCollection.component";
import { Quiz as QuizComponent } from "./components/Quiz.component";
import { AppDispatch, RootState, useAppSelector } from "../../store";
import { selectEpisodeByIdentifier, selectTraining } from "../../store/slices/training.slice";
import { EmptyAnimated } from "../../components/EmptyAnimated";
import { Cell, LoaderAnimated, Row } from "../../components";
import { QuizTileWrapper } from "./components/Training.styles";
import { Training } from "./components/Training.component";
import { configService } from "../../services";

export const useTraining = (
  dispatch: AppDispatch,
  campaignIdentifier: string | null = null,
  showTitle = true,
  groupCategories = configService.current().learnProps.groupCategories
) => {

  const trainingState = useAppSelector(selectTraining);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getTraining(dispatch, campaignIdentifier);
      console.log("Done")
      setIsLoading(false);
    }
    fetchData();
  }, [dispatch, campaignIdentifier]);

  const AvailableTraining = useCallback(() => {
    return (
      <>
        {
          (isLoading) ? <LoaderAnimated /> :
            (trainingState.collections.length === 0) ? <EmptyAnimated text="There aren't any quizzes available currently but check back soon!" /> :
              (groupCategories) ?
                trainingState.collections.map((category, inx) => (
                  <TrainingCollection showTitle={showTitle} key={inx}  {...category} />
                )) :
                <Row>
                {trainingState.episodes.map((episode, inx) => (

                  <Cell baseSize={3} key={inx}>
                    <QuizTileWrapper>
                      <Training {...episode} />
                    </QuizTileWrapper>
                  </Cell>

                ))}
                </Row>
        }
      </>
    )
  }, [trainingState, showTitle, isLoading])

  return {
    ...trainingState,
    hasContent: !!trainingState.collections.length,
    AvailableTraining,
  }
}

export const useQuiz = (episodeId: string) => {

  const episode = useAppSelector((state: RootState) => selectEpisodeByIdentifier(state, episodeId));
  const Quiz = () => {
    return episode ? <QuizComponent  {...episode} /> : null;
  }

  return {
    episode,
    Quiz,
  }
}